<template>

    <loader :active="loading" message="Please wait..." />

    <div class="common-page-cont credits-history-page-cont">
        <div class="common-box">
           
            <div class="box-body">
                <div class="data-table-cont">
                    <div class="table-responsive table-normal-view">
                        <table id="example" class="data-table table no-border dataTable no-footer">
                            <thead class="sticky-top">
                                <tr>
                                    <th class="no-wrap width30">Credits History</th>
                                    <th class="no-wrap width30">Date</th>
                                    <th class="no-wrap width40">Credits Use Details</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(credit, index) in creditsHistory" :key="index">
                                    <td class="no-wrap text-start">
                                        <span class="td-box"
                                            :class="{ 'box-green': credit.is_added, 'box-red': !credit.is_added }">
                                            {{ credit.is_added ? '+ ' : '- ' }}{{ credit.keywords_count }} cred
                                        </span>
                                    </td>
                                    <td class="no-wrap text-start responsive-wrap max-width110-mobile">
                                        {{ credit.date_time || 'N/A' }}
                                    </td>
                                    <td class="no-wrap text-start">
                                        {{ formatDetails(credit.details) }} </td>
                                </tr>
                            </tbody>

                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <copyRight></copyRight>

</template>

<script>
import axiosIns from '@/axios';
import Loader from '../Loader.vue';
import copyRight from '../copyRight.vue';
export default {
    components: {
        Loader,
        copyRight
    },
    data() {
        return {
            creditsHistory: [],
            loading: false
        };
    },
    methods: {
        async fetchCreditsHistory(page) {
            this.loading = true;
            try {
                const response = await axiosIns.get('/user/history');
                this.loading = false
                this.creditsHistory = response.data.history;
            } catch (error) {
                console.error('Error fetching plans:', error);
                this.loading = false
            }
        },
        formatDetails(details) {
            return details.replace(/[()]/g, '').replace(/\s+/g, ' | ');
        }
    },
    beforeMount() {
        this.fetchCreditsHistory();
    },
};


</script>

<style scoped></style>