<template>
      <Loader :active="loading" message="Please wait..." />

  <div class="domain-projects-page-cont">
    <div class="domain-projects-table-cont">
      <div class="domain-projects-table-box">
        <!-- <div class="box-top box-top-with-filter-new">
          <div class="table-top-bar">
            <div class="top-left">
              <div class="form-group search-group">
                <input class="table-search-input" placeholder="Search Here" v-model="searchTerm"  @input="fetchProjects" />
              </div>
            </div>
          </div>
        </div> -->
        <div class="box-top box-top-with-filter-new justify-content-between">
            <div class="table-top-left-info d-flex flex-wrap align-items-center row-gap-3">
                <router-link to="/domain-check" class="btn btn-light admin-common-btn me-4">
                    <img class="img-fluid"
                        src="https://serpplus.pluspromotions.co.uk/images/icon-check-serp.svg"
                        alt="Check Serp Icon" />
                    Check Domain Rank
                </router-link>
            </div>
            <div class="table-top-bar">
                <div class="top-left">
                    <div class="form-group search-group">
                      <input class="table-search-input" placeholder="Search Here" v-model="searchTerm"  @input="fetchProjects" />
                    </div>
                </div>
                <div class="top-right">
                    <div class="form-group btn-group">
                        <button class="btn btn-light filter-btn search-btn">Search</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="box-body">
          <div class="data-table-cont">
            <div
              class="table-responsive table-container table-normal-view table-domain-projects"
               @scroll="handleScroll"
            >
              <table
                id="example3"
                class="data-table table table-competitor-view dataTable no-footer"
              >
                <thead>
                  <tr>
                    <th class="no-wrap align-left">
                      <div class="th-short-box">
                        <div class="txt-icon-cont">
                          <span class="th-txt">Domain</span>
                          <img
                            class="img-fluid th-icon"
                            src="https://serpplus.pluspromotions.co.uk/images/icon-question-circle.svg"
                            alt="Question Icon"
                            title="Domain"
                          />
                        </div>
                      </div>
                    </th>
                    <th class="no-wrap align-left">
                      <div class="th-short-box">
                        <div class="txt-icon-cont">
                          <span class="th-txt">Location</span>
                        </div>
                      </div>
                    </th>
                    <th class="no-wrap align-left">
                      <div class="th-short-box">
                        <div class="txt-icon-cont">
                          <span class="th-txt">Limit</span>
                        </div>
                      </div>
                    </th>
                    <th class="no-wrap align-left">
                      <div class="th-short-box">
                        <div class="txt-icon-cont">
                          <span class="th-txt">Date Created</span>
                        </div>
                      </div>
                    </th>
                    <th class="no-wrap align-center">
                      <div class="th-short-box">
                        <div class="txt-icon-cont">
                          <span class="th-txt">Open Project</span>
                        </div>
                      </div>
                    </th>
                    <th class="no-wrap align-center" style="width: 67px">
                      <div class="th-short-box">
                        <div class="txt-icon-cont">
                          <span class="th-txt">Remove</span>
                        </div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="project in allData"
                    :key="project.id"
                    class="no-wrap"
                  >
                    <td class="align-left">
                      <!-- <input class="table-checkbox form-control" type="checkbox"> -->
                      <router-link :to="'/domain-view/' + project.id">{{
                        project.target
                      }}</router-link>
                    </td>
                    <td class="align-left">{{ project.location_name }}</td>
                    <td class="align-left">{{ project.limit }}</td>
                    <td class="align-left">{{ project.created_at }}</td>
                    <td class="align-center">
                      <p class="text-highlight">
                        <router-link
                          :to="'/domain-view/' + project.id"
                          class="text-primary"
                        >
                          Open
                          <img
                            class="img-fluid"
                            src="https://serpplus.pluspromotions.co.uk/images/icon-open-link.svg"
                            alt="Open Link Icon"
                            title="Open"
                          />
                        </router-link>
                      </p>
                    </td>
                    <td class="align-center">
                      <a
                        class="table-action-btn-new"
                        href="#"
                        @click="deleteProject(project)"
                        ><img
                          class="img-fluid"
                          src="https://serpplus.pluspromotions.co.uk/images/icon-bin.svg"
                          alt="Remove Icon"
                      /></a>
                    </td>
                  </tr>

                  <tr v-if="loading">
                    <td colspan="10" class="text-center">
                      <p style="font-size: 1.2em">⏳ Loading...</p>
                    </td>
                  </tr>

                  <!-- No Data State -->
                  <tr v-else-if="!allData.length">
                    <td colspan="10" class="text-center">
                      <p style="font-size: 1.2em">🧐 No Data Available</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="copyright-cont">
    <p class="copyright-text">
      &copy; 2024 Plus Promotions UK LTD | Registered in UK, Company Number
      13871077, VAT Number 438499640
    </p>
  </div>
</template>

<script setup>
import { onMounted, ref, watch} from "vue";
import axiosIns from "@/axios";
import Loader from "../Loader.vue";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

const projects = ref([]);
const currentPage = ref(1);
const lastPage = ref(1);
const totalEntries = ref(0);
const searchTerm = ref("");
const loading = ref(false);


const allData = ref([]);

const handleScroll = (event) => {
  const container = event.target;

  if (container.scrollHeight > container.clientHeight) {
    if (
      container.scrollTop + container.clientHeight >=
      container.scrollHeight
    ) {
      loadMore();
    }
  }
};

const loadMore = () => {
  if (currentPage.value >= lastPage.value) {
    console.log("No more data to load.");
    return;
  }

  setTimeout(() => {
    currentPage.value++;
    fetchProjects();
  }, 1000);
};

const fetchProjects = async (isSearch = false) => {
  loading.value = true;

  try {
    const response = await axiosIns.get("/domain/list-domain-projects", {
      params: {
        page: currentPage.value,
        search: searchTerm.value,
      },
    });
    loading.value = false;

    if (isSearch) {
      allData.value = response.data.data.domainCheckers;
    } else {
      allData.value.push(...response.data.data.domainCheckers);
    }

    currentPage.value = response.data.data.pagination.current_page;
    lastPage.value = response.data.data.pagination.last_page;
    totalEntries.value = response.data.data.pagination.total;
  } catch (error) {
    loading.value = false;
    console.error("Error fetching projects:", error);
  }
};


const deleteProject = (project) => {
  const confirmation = confirm(
    `Are you sure you want to delete project ${project.target}?`
  );
  if (confirmation) {
    axiosIns
      .delete(`/domain/delete-domain-project/${project.id}`)
      .then((response) => {
        toast.success("Project deleted successfully", {
          position: toast.POSITION.TOP_RIGHT,
          duration: 6000,
        });
        projects.value = projects.value.filter((p) => p.id !== project.id);
      })
      .catch((error) => {
        toast.error(`Failed to delete project: ${error.message}`, {
          position: toast.POSITION.TOP_RIGHT,
          duration: 6000,
        });
      });
  }
};


watch(searchTerm, (newSearchTerm) => {
  if (newSearchTerm.trim() !== "") {
    fetchProjects(true);
  } else {
    fetchProjects();
  }
});

onMounted(() => {
  fetchProjects();
});
</script>

<style scoped>
.search-container {
  position: relative;
  display: inline-block;
}

.search-input {
  padding-left: 2rem;
  /* Adjust this value as needed */
}

.search-icon {
  position: absolute;
  left: 0.5rem;
  /* Adjust this value as needed */
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  /* Prevent the icon from blocking input clicks */
}

.autocomplete {
  position: relative;
}

.autocomplete-results {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 4px;
  background-color: #fff;
}

.autocomplete-result {
  cursor: pointer;
}

.autocomplete-result:hover {
  background-color: #f0f0f0;
}

.autocomplete-no-results {
  padding: 8px;
  color: #999;
}

input:disabled {
  color: #212529 !important;
  background-color: rgba(59, 59, 59, 0.1) !important;
  cursor: not-allowed;
}


.table-container {
  max-height: 1500px;
  overflow-y: auto;
}

.data-table {
  width: 100%;
  border-collapse: collapse;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1;
}
</style>
