<template>
     <div class="copyright-cont">
        <p class="copyright-text">
            &copy; <!--2024--> {{ currentYear }} Plus Promotions UK LTD | Registered in UK, Company Number 13871077, VAT Number 438499640
        </p>
    </div>
</template>

<script>
export default {
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>