<template>

  <div class="admin-header-top">
    <div class="header-top-left-cont">
      <a id="header-toggle" class="toggle-icon-cont">
        <img class="toggle-icon" src="https://serpplus.pluspromotions.co.uk/images/circle-dot.svg"
          alt="Nav Toggle Icon" />
      </a>

      <span class="top-page-title">{{ $route.name }}</span>
    </div>
    <div class="header-user-cont">
      <router-link to='/credits-list' id="left-credits-btn" class="admin-common-btn btn-left-credits"
        v-show="userRole !== 'Superadmin'">
        <img class="icon-btn" src="https://serpplus.pluspromotions.co.uk/images/icon-coin.svg"
          alt="Coin Icon">
        <span class="btn-txt">
          <strong>{{ userCredits }} Credits Left</strong>
          Upgrade for more Credits
        </span>
      </router-link>

      <a class="btn-top-notification active">
        <img src="https://serpplus.pluspromotions.co.uk/images/icon-bell.svg"
          alt="Notification Icon">
      </a>

      <div class="user-box">

        <a id="top-user" class="header-icon-cont icon-user" style="cursor: pointer;">
          <div class="user-img-cont">
            <img class="user-img"
              src="https://serpplus.pluspromotions.co.uk/images/user-avatar3.png"
              alt="User Photo">
            <span class="profile-active-dot active"></span>
          </div>
          <span class="name-user-type">
            <b>{{ userName }}</b>
            <i>{{ userRole }}</i>
          </span>
        </a>

        <div id="profile-pop" class="common-popup-cont profile-pop">
          <div class="pop-cont">

            <div class="profile-pop-top">
              <div class="user-avatar">
                <!-- Updated with router-link -->
                <router-link to="/user-profile">
                  <img src="https://serpplus.pluspromotions.co.uk/images/user-avatar3.png"
                    alt="User Icon" />
                </router-link>
                <span class="profile-active-dot active"></span>
              </div>
              <div class="user-name-role">
                <h2 class="user-name">{{ userName }}</h2>
                <p class="user-role">{{ userRole }}</p>
              </div>
            </div>


            <div class="other-details">
              <p class="user-email-cont">
                <span class="user-email" :title="email">{{ email }}</span>
              </p>
            </div>

            <div class="popup-bottom">
              <router-link to="/user-profile" class="admin-common-btn common-btn-new" style="cursor: pointer;">
                <img class="icon"
                  src="https://serpplus.pluspromotions.co.uk/images/icon-profile-pop1.svg"
                  alt="Icon Sign Out"> Edit Your Profile
              </router-link>
              <router-link to="/privacy-policy" class="admin-common-btn common-btn-new" style="cursor: pointer;">
                <img class="icon"
                  src="https://serpplus.pluspromotions.co.uk/images/icon-profile-pop2.svg"
                  alt="Icon Sign Out"> Privacy Policy
              </router-link>
              <router-link to="/faq-customer" class="admin-common-btn common-btn-new" style="cursor: pointer;">
                <img class="icon"
                  src="https://serpplus.pluspromotions.co.uk/images/icon-profile-pop3.svg"
                  alt="Icon Sign Out"> Help & FAQ
              </router-link>
              <a class="admin-common-btn common-btn-new" @click="goToAdmin" v-show="isAdmin"><img class="icon"
                  src="https://serpplus.pluspromotions.co.uk/images/icon-profile-pop5.svg"
                  alt="Icon Sign Out"> Switch Admin</a>
              <a class="admin-common-btn common-btn-new btn-red" @click="logout" style="cursor: pointer;"><img
                  class="icon"
                  src="https://serpplus.pluspromotions.co.uk/images/icon-profile-pop4.svg"
                  alt="Icon Sign Out"> Logout</a>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

  <div class="top-page-title-for-mobile">{{ $route.name }}</div>


 

</template>

<script>
import EventBus from "@/EventBus";
import axiosIns from "@/axios";
import $ from "jquery";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { StripeCheckout } from "@vue-stripe/vue-stripe";

export default {
  components: {
    StripeCheckout,
  },
  data() {
    return {
      userName: "",
      userCredits: "",
      userRole: "",
      isAdmin: false,
      projects_count: null,
    };
  },
  beforeMount() {

    const userData = localStorage.getItem('userData');
    if (userData) {
      const parsedUserData = JSON.parse(userData);
      this.userName = parsedUserData.name ?? "";
      this.userCredits = parsedUserData.credits ?? "";
      this.userRole = parsedUserData.role[0];
      this.email = parsedUserData.email;
      this.userType = parsedUserData.type;
    }
  },
  mounted() {
    const userData = localStorage.getItem("userData");
    if (userData) {
      const parsedUserData = JSON.parse(userData);
      this.userName = parsedUserData.name ?? "";
      this.userCredits = parsedUserData.credits ?? "";
      this.userRole = parsedUserData.role[0];
      this.email = parsedUserData.email;
      this.userType = parsedUserData.type;
    }
    EventBus.$on("loadCredits", (data) => {
      if (data) {
        this.loadUser();
      }
    })

    $(document).ready(function () {
      $("#top-user").on("click", function (event) {
        event.stopPropagation();
        var profilePop = $("#profile-pop");
        if (profilePop.css("display") === "none") {
          profilePop.css("display", "block");
          $("body").on("click", closePopupOutsideTwo);
        } else {
          profilePop.css("display", "none");
          $("body").off("click", closePopupOutsideTwo);
        }
      });

      function closePopupOutsideTwo(event) {
        const popup = $("#profile-pop");
        if (!popup.is(event.target) && event.target !== $("#top-user")[0]) {
          popup.css("display", "none");
          $("body").off("click", closePopupOutsideTwo);
        }
      }

      $("#close-profile-pop").on("click", function (event) {
        event.stopPropagation();
        $("#profile-pop").css("display", "none");
      });
    });

    $(document).ready(function () {
      $("#live-contact-btn").click(function () {
        $("#live-contact-box").slideToggle("slow");
        $("#live-contact-btn").slideToggle("hide");
      });
      $("#box-close").click(function () {
        $("#live-contact-box").slideToggle("hide");
        $("#live-contact-btn").slideToggle("show");
      });
    });

    const notificationButton = $("#top-notification");
    const notificationPopup = $("#notification-pop");
    const closeNotificationButton = $("#close-notification-pop");

    const checkSerpPopup = $("#checkSerp-pop");

    checkSerpPopup.on("click", function (event) {
      event.stopPropagation();
      checkSerpPopup.css("display", "block");
      $("body").on("click", this.closePopupOutside);
    });

    notificationButton.on("click", function (event) {
      event.stopPropagation();
      notificationPopup.css("display", "block");
      $("body").on("click", this.closePopupOutside);
    });

    closeNotificationButton.on("click", function (event) {
      event.stopPropagation();
      notificationPopup.css("display", "none");
    });

    const showNavbar = (toggleId, navId, bodyId, headerId) => {
      const toggle = $(`#${toggleId}`),
        nav = $(`#${navId}`),
        bodypd = $(`#${bodyId}`),
        headerpd = $(`#${headerId}`);

      if (toggle && nav && bodypd && headerpd) {
        toggle.on("click", () => {
          nav.toggleClass("show");
          toggle.toggleClass("bx-x");
          bodypd.toggleClass("body-pd");
          headerpd.toggleClass("body-pd");
        });
      }
    };

    showNavbar("header-toggle", "nav-bar", "body-pd", "header");

    const linkColor = $(".nav_link");

    function colorLink() {
      if (linkColor) {
        linkColor.removeClass("active");
        $(this).addClass("active");
      }
    }
    linkColor.on("click", colorLink);
  },
  methods: {
    closePopupOutside(event) {
      const popup = $("#notification-pop");
      if (
        !popup.is(event.target) &&
        event.target !== $("#top-notification")[0]
      ) {
        popup.css("display", "none");
        $("body").off("click", this.closePopupOutside);
      }
    },
    async logout() {
      try {
        await axiosIns.get("/logout");

        localStorage.clear();

        setTimeout(() => {
          window.location.href = "/login";
        }, 1000);

        this.$router.push("/login");

        // Show success message
        toast.success("Logout Successful!", {
          position: toast.POSITION.TOP_RIGHT,
          duration: 6000
        });
      } catch (error) {
        console.error("Logout failed:", error);

        // Show error message
        toast.error("Logout Failed!", {
          position: toast.POSITION.TOP_RIGHT,
          duration: 6000
        });
      }
    },
    async loadUser() {
      try {
        const data = await axiosIns.get("/user/show");
        localStorage.setItem("userData", JSON.stringify(data.data.userData));
        this.projects_count = data.data.userData.projects_count;

        const userData = localStorage.getItem("userData");
        const isAdminn = localStorage.getItem("isAdmin");
        this.isAdmin = isAdminn == "true" ? true : false;
        if (userData) {
          const parsedUserData = JSON.parse(userData);
          this.userName = parsedUserData.name ?? "";
          this.userCredits = parsedUserData.credits ?? "";
          this.userRole = parsedUserData.role[0];
          this.email = parsedUserData.email;
        }
      } catch (error) {
        console.error("Logout failed:", error);
      }
    },
    async goToAdmin() {
      const response = await axiosIns.get(`auth/change-auth/${1}`);

      const accessToken = response.data.accessToken;
      const userAbilities = response.data.userAbilities;
      const userData = response.data.userData;

      localStorage.setItem("accessToken", JSON.stringify(accessToken));
      localStorage.setItem("userAbilities", JSON.stringify(userAbilities));
      localStorage.setItem("userData", JSON.stringify(userData));
      localStorage.setItem("isAdmin", false);

      toast.success("Login Successfully!", {
        position: toast.POSITION.TOP_RIGHT,
        duration: 6000, // 6000 milliseconds timeout
      });

      window.location.href = "/";
    },
  },
};
</script>

<style scoped>
/* Style for unchecked switch */
.form-check-input {
  background-color: #007bff !important;
  /* Change to your desired color for unchecked state */
}

/* Style for checked switch */
.form-check-input:checked {
  background-color: #007bff !important;
  /* Change to your desired color for checked state */
}

.credits-container {
  display: flex;
  align-items: center;
}

.credit-icon {
  margin-right: 10px;
}

.credit-info {
  display: flex;
  flex-direction: column;
}

.credit-label {
  font-weight: bold;
}

.projects-info {
  font-size: 14px;
  color: #888;
}
</style>
