<template>

    <loader :active="loading" message="Please wait..." />


    <!-- <a 
                                        class="admin-common-btn btn-select"
                                      data-bs-toggle="modal"
                                        data-bs-target="#modal-add-plan"
                                      >
                                        Add Plan
                                        </a> -->

    <div class="admin-body no-bg-space">
        <div class="admin-body-box">
            <div class="admin-box-row row">
                <div class="admin-box-col plan-box-col col-xs-12 col-md-6 col-lg-4 col-xl-3" v-for="plan in plans"
                    :key="plan.id">
                    <div class="admin-box box-user-plans">
                        <h2 class="admin-box-title with-plan-price">
                            <span class="plan-name">{{ plan.name }}</span>
                            <span class="plan-price">
                                £{{ plan.monthly_price }} <sub>/ Month</sub>
                            </span>
                            <br>
                            <span class="plan-price">
                                £{{ plan.yearly_price }} <sub>/ Year</sub>
                            </span>
                        </h2>
                        <!-- <div class="plan-price-container">
                            <div class="plan-price">
                                <span class="price">£{{ plan.monthly_price }}</span>
                                <span class="period">/ Month</span>
                            </div>
                            <div class="plan-price">
                                <span class="price">£{{ plan.yearly_price }}</span>
                                <span class="period">/ Year</span>
                            </div>
                        </div> -->
                        <ul class="common-ul plan-features dark">
                            <li>{{ plan.monthly_credits }} Credits / month</li>
                            <li>{{ plan.yearly_credits }} Credits / year</li>
                        </ul>
                        <div class="admin-box-btn-cont">
                            <a class="admin-common-btn btn-full" @click="editPlan(plan)" data-bs-toggle="modal"
                                data-bs-target="#modal-edit-plan">Edit Plan</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="admin-box-row row">
                <div class="admin-box-col col-xs-12 col-md-12 col-lg-12">
                    <div class="admin-box box-user-project-table mb-0 height-100">
                        <div class="data-table-cont no-pagination">
                            <div id="example_wrapper" class="dataTables_wrapper dt-bootstrap5 no-footer">
                                <div class="row dt-row">
                                    <div class="col-sm-12">
                                        <div class="table-responsive table-manage-plans">
                                            <!-- <table id="example" class="data-table table table-border-none table-border-box ptb-10 dataTable no-footer table-top-m22" aria-describedby="example_info"> -->
                                            <table id="example"
                                                class="data-table table table-striped table-border-none dataTable"
                                                aria-describedby="example_info">
                                                <thead class="sticky-top">
                                                    <tr>
                                                        <th class="no-wrap">Name</th>
                                                        <th class="no-wrap">Description</th>
                                                        <th class="no-wrap">Monthly Price</th>
                                                        <th class="no-wrap">Annually Price</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="plan in plans" :key="plan.id"
                                                        :class="{ odd: plan.id % 2 !== 0, even: plan.id % 2 === 0 }">
                                                        <td class="no-wrap">{{ plan.name }}</td>
                                                        <td class="no-wrap">{{ plan.description }}</td>
                                                        <td class="no-wrap">£ {{ plan.monthly_price }}</td>
                                                        <td class="no-wrap">£ {{ plan.yearly_price }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- MODAL - ADD PLAN - START -->
    <div id="modal-add-plan" class="common-modal-cont modal fade" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-490 modal-dialog-centered">
            <div class="modal-content">
                <form action="">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            <span class="mod-title">Add Plan</span>
                            <!-- <div class="info-cont tooltip-trigger">
                                <img class="info-icon" src="images/icon-info-circle.svg" alt="Info">
                                <div class="custom-tooltip">
                                    <div class="tooltip-cont">
                                        <img class="icon" src="images/icon-info-circle-white.svg" alt="Info">
                                        <span class="text">Add Plan</span>
                                    </div>
                                </div>
                            </div> -->
                        </h5>
                        <a class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                            <img src="images/icon-popup-close.svg" alt="Close">
                        </a>
                    </div>
                    <div class="modal-body">
                        <div class="modal-form-cont check-serp-form-cont">
                            <div class="form-group">
                                <label>Plan Name</label>
                                <input class="form-control" v-model="form.name" type="text"
                                    placeholder="Enter Plan Name" required>
                            </div>
                            <div class="form-group">
                                <label>Plan Description</label>
                                <Textarea class="form-control" v-model="form.description"
                                    placeholder="Enter Description" required></Textarea>
                            </div>
                            <div class="form-group">
                                <label>Projects Limit*</label>
                                <input class="form-control" v-model="form.projects_count" type="text"
                                    placeholder="Enter Projects Limit" required>
                            </div>
                            <div class="form-group">
                                <label>Domain Projects Limit*</label>
                                <input class="form-control" v-model="form.domain_project_count" type="text"
                                    placeholder="Enter Domain Projects Limit" required>
                            </div>
                            <div class="form-group">
                                <label>Monthly Price</label>
                                <input class="form-control" v-model="form.monthly_price" type="text"
                                    placeholder="Enter Monthly Price" required>
                            </div>
                            <div class="form-group">
                                <label>Monthly Credits</label>
                                <input class="form-control" v-model="form.monthly_credits" type="text"
                                    placeholder="Enter Credits" required>
                            </div>
                            <div class="form-group">
                                <label>Yearly Price</label>
                                <input class="form-control" v-model="form.yearly_price" type="text"
                                    placeholder="Enter Yearly Price" required>
                            </div>
                            <div class="form-group">
                                <label>Yearly Credits</label>
                                <input class="form-control" v-model="form.yearly_credits" type="text"
                                    placeholder="Enter Credits" required>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <Button class="admin-common-btn btn-bordered btn btn-cancel" type="button"
                            data-bs-dismiss="modal" aria-label="Close">Cancel</Button>
                        <Button class="admin-common-btn btn btn-primary btn-save" data-bs-dismiss="modal"
                            @click="savePlan">Save</Button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <!-- MODAL - ADD PLAN - END -->

    <!-- MODAL - EDIT PLAN - START -->
    <div id="modal-edit-plan" class="common-modal-cont modal fade" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-490 modal-dialog-centered">
            <div class="modal-content">
                <form action="">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            <span class="mod-title">Edit Plan</span>
                            <!-- <div class="info-cont tooltip-trigger">
                                <img class="info-icon" src="images/icon-info-circle.svg" alt="Info">
                                <div class="custom-tooltip">
                                    <div class="tooltip-cont">
                                        <img class="icon" src="images/icon-info-circle-white.svg" alt="Info">
                                        <span class="text">Edit Plan</span>
                                    </div>
                                </div>
                            </div> -->
                        </h5>
                        <a class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                            <img src="images/icon-popup-close.svg" alt="Close">
                        </a>
                    </div>
                    <div class="modal-body">
                        <div class="modal-form-cont check-serp-form-cont">
                            <div class="form-group">
                                <label>Plan Name</label>
                                <input class="form-control" v-model="form.name" type="text"
                                    placeholder="Enter Plan Name" required>
                            </div>
                            <div class="form-group">
                                <label>Plan Description</label>
                                <Textarea class="form-control" v-model="form.description"
                                    placeholder="Enter Description" required></Textarea>
                            </div>
                            <div class="form-group">
                                <label>Projects Limit*</label>
                                <input class="form-control" v-model="form.projects_count" type="text"
                                    placeholder="Enter Projects Limit" required>
                            </div>
                            <div class="form-group">
                                <label>Domain Projects Limit*</label>
                                <input class="form-control" v-model="form.domain_project_count" type="text"
                                    placeholder="Enter Domain Projects Limit" required>
                            </div>
                            <div class="form-group">
                                <label>Monthly Price</label>
                                <input class="form-control" v-model="form.monthly_price" type="text"
                                    placeholder="Enter Monthly Price" required>
                            </div>
                            <div class="form-group">
                                <label>Monthly Credits</label>
                                <input class="form-control" v-model="form.monthly_credits" type="text"
                                    placeholder="Enter Credits" required>
                            </div>
                            <div class="form-group">
                                <label>Yearly Price</label>
                                <input class="form-control" v-model="form.yearly_price" type="text"
                                    placeholder="Enter Yearly Price" required>
                            </div>
                            <div class="form-group">
                                <label>Yearly Credits</label>
                                <input class="form-control" v-model="form.yearly_credits" type="text"
                                    placeholder="Enter Credits" required>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <Button class="admin-common-btn btn-bordered btn btn-cancel" type="button"
                            data-bs-dismiss="modal" aria-label="Close">Cancel</Button>
                        <Button class="admin-common-btn btn btn-primary btn-update" data-bs-dismiss="modal"
                            @click="updatePlan">Update</Button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <!-- MODAL - EDIT PLAN - END -->
    <div class="copyright-cont">
        <p class="copyright-text">&copy; 2024 Plus Promotions UK LTD | Registered in UK, Company Number 13871077, VAT
            Number 438499640</p>
    </div>
</template>

<script setup>
import axiosIns from '@/axios';
import { ref, computed, onMounted } from 'vue';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import loader from '../Loader.vue';

const form = ref({
    name: '',
    description: '',
    monthly_price: '',
    monthly_credits: '',
    features: '',
    yearly_price: '',
    is_annual: false,
    yearly_credits: '',
    projects_count: '',
    domain_project_count: '',
})
const loading = ref(false)
const planId = ref('')

const editPlan = (plan) => {
    planId.value = plan.id
    console.log(plan);
    form.value = plan
};

const addPlan = () => {
    form.value = {}
}

const plans = ref([])

const fetchPlans = async () => {
    loading.value = true
    try {
        const data = await axiosIns.get('plans');
        loading.value = false
        plans.value = data.data.plans;
    } catch (error) {
        console.error('Error:', error.message);
    }
};
const savePlan = async () => {
    try {
        await axiosIns.post('plans', form.value);

        toast.success('Plan added successfully', {
            position: toast.POSITION.TOP_RIGHT,
            duration: 6000
        });
        fetchPlans();
    } catch (error) {
        console.error('Error:', error.message);
    }
};

const updatePlan = async () => {
    try {
        await axiosIns.put(`/plans/£{planId.value}`, form.value);

        toast.success('Plan updated successfully', {
            position: toast.POSITION.TOP_RIGHT,
            duration: 6000
        });
        fetchPlans();
    } catch (error) {
        console.error('Error:', error.message);
    }
}

onMounted(() => {
    fetchPlans();
});
</script>

<style scoped>
.box-user-plans {
    /* border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    background-color: #f9f9f9; */
}

.admin-box-title {
    margin-bottom: 10px;
    font-size: 20px;
}

.plan-price-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
}

.plan-price {
    font-size: 24px;
    margin-bottom: 5px;
}

.period {
    font-size: 18px;
}

.common-ul {
    /* list-style-type: none;
    padding: 0;
    margin: 0; */
}

.plan-features li {
    margin-bottom: 5px;
}

.admin-box-btn-cont {
    text-align: center;
}

/* Responsive Styles */
@media only screen and (max-width: 768px) {
    /* .plan-price {
        font-size: 20px;
    }
    .period {
        font-size: 16px;
    }
    .admin-box-title {
        font-size: 18px;
    } */
}

@media only screen and (max-width: 576px) {
    /* .box-user-plans {
        padding: 10px;
    }
    .admin-box-title {
        font-size: 16px;
    }
    .plan-price {
        font-size: 16px;
    }
    .period {
        font-size: 14px;
    }
    .plan-features li {
        font-size: 14px;
    } */
}
</style>
