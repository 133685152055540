<template>
    <div class="dashboard-container" v-show="userRole == 'Superadmin'">
        <!-- Dashboard Cards -->
        <div class="cards-container">
            <!-- Total Revenue Card -->
            <div class="card">
                <div class="card-content">
                    <div class="emoji">💰</div>
                    <div class="card-title">Total Revenue</div>
                    <div class="card-value">{{ dashboardData?.totalRevenue?.totalRevenue }} {{ dashboardData?.totalRevenue?.currency }}</div>
                </div>
            </div>

            <!-- Total Users Card -->
            <div class="card">
                <div class="card-content">
                    <div class="emoji">👥</div>
                    <div class="card-title">Total Users</div>
                    <div class="card-value">{{ dashboardData?.totalUsers }}</div>
                </div>
            </div>

            <!-- Trial Users Card -->
            <div class="card">
                <div class="card-content">
                    <div class="emoji">🆓</div>
                    <div class="card-title">Trial Users</div>
                    <div class="card-value">{{ dashboardData?.trialUsers }}</div>
                </div>
            </div>

            <!-- Paid Users Card -->
            <div class="card">
                <div class="card-content">
                    <div class="emoji">💳</div>
                    <div class="card-title">Paid Users</div>
                    <div class="card-value">{{ dashboardData?.paidUsers }}</div>
                </div>
            </div>
        </div>

        <!-- Chart with Radio Buttons -->
        <div class="chart-container">
            <div class="filter-container">
                <div class="filter-label">Select Filter:</div>
                <div class="radio-buttons">
                    <label>
                        <input type="radio" v-model="selectedFilter" value="weekly" @change="fetchDashboardData" /> Weekly
                    </label>
                    <label>
                        <input type="radio" v-model="selectedFilter" value="monthly" @change="fetchDashboardData" /> Monthly
                    </label>
                </div>
            </div>

            <apexchart
                type="line"
                height="350"
                :options="chartOptions"
                :series="chartSeries"
            />
        </div>
    </div>

    <!-- Copyright Section -->
    <div class="copyright-cont">
        <p class="copyright-text">© 2024 Plus Promotions UK LTD | Registered in UK, Company Number 13871077, VAT Number 438499640</p>
    </div>
</template>

<script>
import axiosIns from '@/axios';
import VueApexCharts from 'vue3-apexcharts';

export default {
    components: {
        apexchart: VueApexCharts
    },
    data() {
        return {
            dashboardData: null,
            userRole: null,
            selectedFilter: 'weekly', 
            chartOptions: {
                chart: {
                    id: 'credits-chart',
                    type: 'line',
                    zoom: { enabled: false },
                    toolbar: { show: false },
                },
                title: {
                    text: 'Daily Credits Usage By Customers',
                    align: 'center',
                    style: {
                        fontSize: '18px',
                        fontWeight: '600',
                        color: '#333',
                    }
                },
                xaxis: {
                    categories: [],
                    labels: {
                        style: {
                            colors: '#666',
                            fontSize: '12px',
                        }
                    },
                },
                yaxis: {
                    labels: {
                        style: {
                            colors: '#666',
                            fontSize: '12px',
                        }
                    },
                },
                grid: {
                    borderColor: '#e4e7ed',
                    row: {
                        colors: ['#fff', '#f9f9f9'],
                        opacity: 0.5
                    }
                },
                stroke: {
                    curve: 'smooth',
                    width: 2,
                },
                colors: ['#007bff'],
                tooltip: {
                    theme: 'light',
                },
                legend: {
                    position: 'top',
                    horizontalAlign: 'right',
                },
            },
            chartSeries: [],
        };
    },
    mounted() {
        const userData = JSON.parse(localStorage.getItem('userData'));
        this.userRole = userData?.role[0];
        this.fetchDashboardData();
    },
    methods: {
        async fetchDashboardData() {
            try {
                const response = await axiosIns.get('/dashboard', {
                    params: {
                        filter: this.selectedFilter  // Send the selected filter as a parameter
                    }
                });
                this.dashboardData = response.data;
                this.updateChartData();
            } catch (error) {
                this.error = error.message;
            } finally {
                this.loading = false;
            }
        },
        updateChartData() {
            const selectedData = this.selectedFilter === 'weekly' ? this.dashboardData.dailyCredits.slice(0, 7) : this.dashboardData.dailyCredits;
            this.chartOptions.xaxis.categories = selectedData.map(item => item.date);
            this.chartSeries = [
                {
                    name: 'Credits',
                    data: selectedData.map(item => item.count),
                },
            ];
        },
    },
    watch: {
        selectedFilter(newValue) {
            this.updateChartData();
        },
    },
};
</script>

<style scoped>
.dashboard-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 30px;
    background-color: #f9f9f9;
}

.cards-container {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: space-between;
}

.card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    width: 22%;
    padding: 25px;
    text-align: center;
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
}

.card:hover {
    transform: translateY(-5px);
}

.card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.emoji {
    font-size: 48px;
    margin-bottom: 15px;
    color: #007bff;
}

.card-title {
    font-size: 20px;
    font-weight: 600;
    color: #333;
    margin-bottom: 10px;
}

.card-value {
    font-size: 28px;
    font-weight: 700;
    color: #007bff;
}

.chart-container {
    width: 100%;
    background-color: #fff;
    padding: 25px;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.filter-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
}

.filter-label {
    font-size: 18px;
    font-weight: 600;
    color: #333;
}

.radio-buttons label {
    font-size: 16px;
    font-weight: 500;
    color: #555;
    cursor: pointer;
    margin-right: 15px;
}

.radio-buttons input[type="radio"] {
    margin-right: 5px;
    transform: scale(1.2);
}

/* Responsive Styles */
@media (max-width: 1024px) {
    .cards-container {
        flex-direction: column;
        align-items: center;
    }

    .card {
        width: 80%;
        margin-bottom: 15px;
    }
}

@media (max-width: 768px) {
    .dashboard-container {
        padding: 15px;
    }

    .card {
        width: 100%;
    }

    .filter-container {
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
    }

    .chart-container {
        padding: 15px;
    }

    .card-title {
        font-size: 18px;
    }

    .card-value {
        font-size: 24px;
    }
}

@media (max-width: 480px) {
    .emoji {
        font-size: 36px;
    }

    .filter-label {
        font-size: 16px;
    }

    .radio-buttons label {
        font-size: 14px;
    }
}
</style>
